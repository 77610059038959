html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}
  

HTML5 display-role reset for older browsers
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
  

body {
  line-height: 1;
}
  

ol, ul {
  list-style: none;
  padding: 0;
}
  
a {
  color: #000;
  text-decoration: none;
}
  
blockquote, q {
  quotes: none;
}


blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
  

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input, textarea {
  box-sizing: inherit;
  color: #4d4d4d;
  border: solid 1px #b3b3b3;
  font-family: 'Lato';
  font-weight: 500;
  &:focus {
    border: solid 1px #0046a0 !important;
    outline: none;
  }
  &::placeholder {
    color: #b3b3b3;
  }
}
  

@mixin font($fname, $fstyle, $fweight, $furl) {
  @font-face {
    font-family: $fname;
		font-style: $fstyle;
		font-weight: $fweight;
		src: url($furl) format('woff2');
  }
}	
@include font('Lato', normal, 400, './../assets/fonts/Lato/Lato-Light.ttf');	
@include font('Lato', normal, 500, './../assets/fonts/Lato/Lato-Regular.ttf');
@include font('Lato', normal, 700, './../assets/fonts/Lato/Lato-Bold.ttf');
@include font('AvenirNext', normal, 700, './../assets/fonts/AvenirNext/AvenirNext-Bold.ttf');


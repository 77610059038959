.lt {
  text-align: left;
}
.ctr {
  text-align: center;
}
.rt {
  text-align: right;
}


h1 {
  font-size: 48px;
  @media (max-width: 767px) {
    font-size: 38px;
  }
}
h2 {
  font-size: 38px;
  @media (max-width: 767px) {
    font-size: 30px;
  }
}
h3 {
  font-size: 30px;
  @media (max-width: 767px) {
    font-size: 24px;
  }
}
h4 {
  font-size: 24px;
  @media (max-width: 767px) {
    font-size: 20px;
  }
}
h5 {
  font-size: 20px;
  @media (max-width: 767px) {
    font-size: 18px;
  }
}
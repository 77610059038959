@import './normalize.scss';
@import './typography.scss';

body {
  font-family: 'Lato';
  font-weight: 500;
  box-sizing: border-box;
}
.width-wrapper {
  max-width: calc(1170px + 68px);
  padding: 0 34px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  @media (max-width: 767px) {
    padding: 0 15px;
  }
  h2 {
    font-family: 'AvenirNext';
    font-weight: 700;
    font-size: 38px;
    text-align: center;
    color: #000;
    line-height: 1.42;
    margin-bottom: 84px;
    @media (max-width: 767px) {
      font-size: 30px;
      line-height: 37px;
    }
  }
  .mokam-button {
    display: block;
    padding: 17px 13px;
    border-radius: 6px;
    background-color: #448aff;
    width: 290px;
    height: 50px;
    text-align: center;
    line-height: inherit;
    cursor: pointer;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    appearance: none;
    border: none;
    color: #fff;
    &:hover {
      background-color: #72a7fe;
    }
    &:active {
      background-color: #356ecc;
      
    }
    &.disabled {
      background-color: #8cc8ff;
    }
  }
  .subtitle {
    margin: 10px 0 50px;
    text-align: center;
    font-size: 18px;
    color: #4d4d4d;
    @media (max-width: 767px) {
      max-width: 280px;
      line-height: 28px;
    }
  }
  .slick-list {
    overflow: hidden;
    .slick-track {
      display: flex; 
    }
  }

  .dots-container {
    display: flex !important;
    gap: 3px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    cursor: pointer;
    li {
      width: 10px;
      height: 10px;
      background-color: #cce2ff;
      border-radius: 50%;
      margin: 0px 4px;
      position: relative;
      overflow: hidden;
      button {
        opacity: 0;
        position: absolute;
        cursor: pointer;
      }
      &.slick-active {
        background-color: #0046a0;
        width: 25px;
        border-radius: 15px;
      }
    }
    @media (max-width: 1024px) {
        bottom: -50px;
    }
    @media (max-width: 767px) {
        bottom: -10px;
    } 
  }
}